<template>
  <accounts />
  <ad-accounts />
</template>

<script lang="ts">
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import {defineComponent} from "vue";
import Accounts from './Accounts.vue'
import AdAccounts from './AdAccounts.vue'

export default defineComponent({
  name: "Google",

  components: {
    Accounts,
    AdAccounts
  },

  mounted() {
    setCurrentPageTitle("Google Integration");
  },

});
</script>

<style scoped>

</style>

import {defineComponent} from "vue";
import PopupMessage from '@/core/helpers/PopupMessage';
import store from '@/store';
import {Actions} from '@/store/enums/StoreEnums';
import ConfirmDialog from '@/core/helpers/ConfirmDialog';
import {mapGetters, mapActions} from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {AxiosResponse} from "axios";
import OverlaySpinner from '@/components/OverlaySpinner.vue';

dayjs.extend(utc);
dayjs.extend(timezone);

type DataPayload = {
  syncingAccountAdAccounts: Record<any, boolean>,
}

export default defineComponent({
  name: "Accounts",

  components: {OverlaySpinner},

  data(): DataPayload {
    return {
      syncingAccountAdAccounts: {},
    };
  },

  computed: {
    ...mapGetters({
      accounts: 'google/accounts/list',
      loading: 'google/accounts/listIsFetching',
      // getSyncingFbProfileAdAccounts: 'getSyncingFbProfileAdAccounts',
    }),
  },

  mounted() {
    this.loadGoogleAccounts();
  },

  methods: {
    ...mapActions({
      loadGoogleAccounts: 'google/accounts/fetch',
      loadGoogleAdAccounts: 'google/adAccounts/fetch',
      fetchGoogleAuthUri: 'google/auth/fetchAuthUri',
      syncAdAccounts: 'google/accounts/syncAdAccounts',
      deleteGoogleAccount: 'google/accounts/delete',
    }),

    requestGoogleAccountAuth() {
      //TODO: move request to vuex module (as an action)
      return this.fetchGoogleAuthUri()
        .then((response: AxiosResponse<any>) => {
          const uri = response.data.authorizationUri;
          this.openGoogleAccountAuthConsentScreen(uri);
        });
    },

    openGoogleAccountAuthConsentScreen(uri) {
      let popup;
      let popupTick;
      const self = this;

      return new Promise(() => {
        popup = window.open(uri, 'Auth Google Account');

        if (popup) {
          popup.focus();

          popupTick = setInterval(() => {
            if (!popup.closed) return;
            self.loadGoogleAccounts({force: true}).then(() => {
              self.loadGoogleAdAccounts({force: true});
            });
            clearInterval(popupTick);
          }, 500);
        }
      })
    },

    deleteAccount(id) {
      ConfirmDialog.show({text: 'Are you sure?', title: 'Delete Google Account', type: 'error', confirmButtonText: 'Delete', icon: 'warning'})
        .then(confirmed => {
          if (confirmed) {
            this.deleteGoogleAccount(id)
              .then(() => {
                PopupMessage.show('Account was deleted successfully', 'success', 'Ok');
                this.loadGoogleAdAccounts({force: true});
              });
          }
        });
    },

    syncAccountAdAccounts(account) {
      this.syncingAccountAdAccounts[account.id] = true;
      this.syncAdAccounts(account.id)
        .then(() => {
          this.loadGoogleAdAccounts({force: true});
        })
        .finally(() => {
          this.syncingAccountAdAccounts[account.id] = false;
        });
    },

    isSyncingAccountAdAccounts(account):boolean {
      return (account.id in this.syncingAccountAdAccounts) ? this.syncingAccountAdAccounts[account.id] : false;
    },

  },
});

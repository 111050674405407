<template>
  <div class="card">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Accounts</span>
      </h3>
      <div class="card-toolbar">
        <button class="btn btn-sm btn-primary" ref="addAccountButton" @click="requestGoogleAccountAuth">
          <span class="indicator-label">Add account</span>
          <span class="indicator-progress">Connecting <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
        </button>
      </div>
    </div>

    <div class="card-body py-3">
      <div class="table-responsive">
        <overlay-spinner v-if="loading"/>

        <table v-else class="table table-row-bordered align-middle gs-0 gy-3">
          <tbody>
            <tr v-for="(account, index) in accounts" :key="`account-row-${index}`">
              <td class="d-flex">
                <div>
                  <div class="symbol symbol-50px me-5">
                    <img :src="account.avatar" class="h-75 align-self-end"/>
                  </div>
                </div>
                <div>
                  <div class="text-dark fw-bolder text-hover-primary mb-1 mt-1 fs-6">{{ account.name }}</div>
                  <span class="text-muted fw-bold d-block">ID: {{ account.id }}</span>
                </div>
              </td>
              <td class="text-end">
                <button class="btn btn-icon btn-bg-light btn-active-color-danger btn-sm me-1" @click="deleteAccount(account.id)">
                  <i class="fas fa-trash" />
                </button>

                <button v-if="!isSyncingAccountAdAccounts(account)"
                        class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                        @click="syncAccountAdAccounts(account)">
                  <i class="fas fa-sync"></i>
                </button>
                <button v-else
                        class="btn btn-sm btn-icon btn-light-dark"
                        :disabled="true">
                  <i class="spinner-border text-success" />
                </button>
              </td>
            </tr>

            <tr v-if="accounts.length === 0">
              <td colspan="3" class="text-center">
                <div class="alert alert-secondary">Add google account to fetch ad accounts</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import PopupMessage from '@/core/helpers/PopupMessage';
import store from '@/store';
import {Actions} from '@/store/enums/StoreEnums';
import ConfirmDialog from '@/core/helpers/ConfirmDialog';
import {mapGetters, mapActions} from 'vuex';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import {AxiosResponse} from "axios";
import OverlaySpinner from '@/components/OverlaySpinner.vue';

dayjs.extend(utc);
dayjs.extend(timezone);

type DataPayload = {
  syncingAccountAdAccounts: Record<any, boolean>,
}

export default defineComponent({
  name: "Accounts",

  components: {OverlaySpinner},

  data(): DataPayload {
    return {
      syncingAccountAdAccounts: {},
    };
  },

  computed: {
    ...mapGetters({
      accounts: 'google/accounts/list',
      loading: 'google/accounts/listIsFetching',
      // getSyncingFbProfileAdAccounts: 'getSyncingFbProfileAdAccounts',
    }),
  },

  mounted() {
    this.loadGoogleAccounts();
  },

  methods: {
    ...mapActions({
      loadGoogleAccounts: 'google/accounts/fetch',
      loadGoogleAdAccounts: 'google/adAccounts/fetch',
      fetchGoogleAuthUri: 'google/auth/fetchAuthUri',
      syncAdAccounts: 'google/accounts/syncAdAccounts',
      deleteGoogleAccount: 'google/accounts/delete',
    }),

    requestGoogleAccountAuth() {
      //TODO: move request to vuex module (as an action)
      return this.fetchGoogleAuthUri()
        .then((response: AxiosResponse<any>) => {
          const uri = response.data.authorizationUri;
          this.openGoogleAccountAuthConsentScreen(uri);
        });
    },

    openGoogleAccountAuthConsentScreen(uri) {
      let popup;
      let popupTick;
      const self = this;

      return new Promise(() => {
        popup = window.open(uri, 'Auth Google Account');

        if (popup) {
          popup.focus();

          popupTick = setInterval(() => {
            if (!popup.closed) return;
            self.loadGoogleAccounts({force: true}).then(() => {
              self.loadGoogleAdAccounts({force: true});
            });
            clearInterval(popupTick);
          }, 500);
        }
      })
    },

    deleteAccount(id) {
      ConfirmDialog.show({text: 'Are you sure?', title: 'Delete Google Account', type: 'error', confirmButtonText: 'Delete', icon: 'warning'})
        .then(confirmed => {
          if (confirmed) {
            this.deleteGoogleAccount(id)
              .then(() => {
                PopupMessage.show('Account was deleted successfully', 'success', 'Ok');
                this.loadGoogleAdAccounts({force: true});
              });
          }
        });
    },

    syncAccountAdAccounts(account) {
      this.syncingAccountAdAccounts[account.id] = true;
      this.syncAdAccounts(account.id)
        .then(() => {
          this.loadGoogleAdAccounts({force: true});
        })
        .finally(() => {
          this.syncingAccountAdAccounts[account.id] = false;
        });
    },

    isSyncingAccountAdAccounts(account):boolean {
      return (account.id in this.syncingAccountAdAccounts) ? this.syncingAccountAdAccounts[account.id] : false;
    },

  },
});
</script>
